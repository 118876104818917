import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoReorderThree } from "react-icons/io5";
import { useAuth } from './../Auth/AuthContext';
import DiscordPanel from './DiscordPanel';
import Mascot from './Mascot';

const NavLink = ({ to, text, currentPath }) => {
    const isActive = currentPath === to;

    return (
        <Link
            to={to}
            className={`text-white h-full flex items-center transition-color duration-200 ${isActive ? 'text-yellow-300 hover:text-yellow-100 underline' : 'hover:underline'}`}
        >
            {text}
        </Link>
    );
};

const Header = () => {
    const { user, membership } = useAuth();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // todo: temp
    const isMember = membership >= 1;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    if (location.pathname === "/tools/to" || location.pathname === "/tools/to2")
        return (
            <div className="bg-black flex px-4 py-0.5 z-10">
                <NavLink to="/" text="Home" currentPath={location.pathname} className="whitespace-nowrap" />
            </div>
        );

    return (
        <div className="flex justify-between items-center bg-black w-full h-16 font-shippori sticky top-0 z-10">
            <Link className="flex gap-2 px-4 hover:bg-zinc-900 h-full items-center self-start" to="/">
                <div className="w-8 h-8 sm:w-12 sm:h-12 text-md font-sm text-white flex items-center justify-center whitespace-nowrap font-bold"><Mascot/></div>
                <div className="lg:flex flex-col gap-2 justify-center hidden">
                    <h1 className="text-white text-2xl font-bold leading-3 whitespace-nowrap">kumakult</h1>
                    <h2 className="text-white text-xs text-zinc-300 whitespace-nowrap">too bad. the queue is always busy.</h2>
                </div>
            </Link>
            <div className="lg:flex left-1/2 absolute transform -translate-x-1/2 justify-center px-auto gap-6 items-center hidden h-full">
                <NavLink to="/" text="Home" currentPath={location.pathname} className="whitespace-nowrap" />
                {isMember && <NavLink to="/bookings" text="Bookings" currentPath={location.pathname} className="whitespace-nowrap" />}
                <NavLink to="/events" text="Events" currentPath={location.pathname} className="whitespace-nowrap" />
                <NavLink to="/drinks" text="Drinks" currentPath={location.pathname} className="whitespace-nowrap" />
                {(user != null && user.id === '148332220120039424') && <NavLink to="/tools/to" text="Zac TO" currentPath={location.pathname} className="whitespace-nowrap" />}
                {(user != null && user.id === '148332220120039424') && <NavLink to="/tools/to2" text="Crew TO" currentPath={location.pathname} className="whitespace-nowrap" />}
                {/* {(user != null && user.id === '148332220120039424') && <NavLink to="/tools/to" text="TO Page" currentPath={location.pathname} className="whitespace-nowrap" />} */}
                {/* <NavLink to="/events/crew-battle" text="CREW BATTLE" currentPath={location.pathname} className="whitespace-nowrap" /> */}
            </div>
            <div className="lg:hidden flex items-center h-full w-full" ref={dropdownRef}>
                <button
                    className="text-white w-full px-4 hover:bg-zinc-900 h-full flex flex gap-2 items-center justify-end"
                    onClick={toggleMenu}
                >
                    { isOpen ? 'Hide' : 'Menu'}
                    < IoReorderThree size={24} className="mt-1"/>
                </button>
                {isOpen && (
                    <div className="absolute top-16 right-0 bg-black w-full py-2 z-10">
                        <div className="flex flex-col gap-4 pb-4 px-4">
                            <NavLink to="/" text="Home" currentPath={location.pathname} className="whitespace-nowrap" />
                            {isMember && <NavLink to="/bookings" text="Bookings" currentPath={location.pathname} className="whitespace-nowrap" />}
                            <NavLink to="/events" text="Events" currentPath={location.pathname} className="whitespace-nowrap" />
                            <NavLink to="/drinks" text="Drinks" currentPath={location.pathname} className="whitespace-nowrap" />
                            {(user != null && user.id === '148332220120039424') && <NavLink to="/tools/to" text="TO Page" currentPath={location.pathname} className="whitespace-nowrap" />}
                            {/* {(user != null && user.id === '148332220120039424') && <NavLink to="/tools/to" text="TO Page" currentPath={location.pathname} className="whitespace-nowrap" />} */}
                            {/* <NavLink to="/events/crew-battle" text="CREW BATTLE" currentPath={location.pathname} className="whitespace-nowrap" /> */}
                        </div>
                    </div>
                )}
            </div>
            <DiscordPanel />
        </div>
    );
}

export default Header;
